<template>
  <div>
    <p v-show="auth.userCtrCd === 'ID' && isFirstCalcTableIndex" style="text-align: right;">
      When the freetime extention applied, additional ADM extention charge will be added as a B/L auxiliary charge.
    </p>
    <div class="freetime_sec" :id="'tb_' + tableInfo.idx" v-if="table.payload.rows.length >= 0 && query !== undefined">
      <div class="flex_box mt20">
        <h2 class="content_title">{{ table.title }}</h2>
        <span class="txt_link" v-if="table.payload.freeTimeInvoice !== null">
          <!-- 인보이스 상태가 리젝, 펜딩, 캔슬인 경우 빨간색으로 노출, 이외 검정색-->
          <span v-if="table.payload.freeTimeInvoice.vaAcctYn === 'Y'">
            <div v-if="table.payload.freeTimeInvoice.apvStsCd === '04' || table.payload.freeTimeInvoice.apvStsCd === '10'">
              <a id="invLink" href="javascript:void(0)" @mouseenter="isToolTipInvMsg = true" @mouseleave="isToolTipInvMsg = false" @click="openPopup('CalcIssueInvMsgPop')" style="font-weight: bold; color: red">{{ getChangeText(table.payload.freeTimeInvoice) }}</a>
            </div>
            <div v-else>
              <a id="invLink" href="javascript:void(0)" @mouseenter="isToolTipInvMsg = true" @mouseleave="isToolTipInvMsg = false" @click="openPopup('CalcIssueInvMsgPop')" style="font-weight: bold; color: black">{{ getChangeText(table.payload.freeTimeInvoice) }}</a>
            </div>
          </span>
          <span v-else-if="table.payload.freeTimeInvoice.apvStsCd === '04' || table.payload.freeTimeInvoice.apvStsCd === '07' || table.payload.freeTimeInvoice.apvStsCd === '10'">
            <a id="invLink" href="javascript:void(0)" @mouseenter="isToolTipInvMsg = true" @mouseleave="isToolTipInvMsg = false" @click="openPopup('CalcIssueInvMsgPop')" style="font-weight: bold; color: red">{{ getChangeText(table.payload.freeTimeInvoice) }}</a>
          </span>
          <span v-else>
            <a id="invLink" href="javascript:void(0)" @mouseenter="isToolTipInvMsg = true" @mouseleave="isToolTipInvMsg = false" @click="openPopup('CalcIssueInvMsgPop')" style="font-weight: bold; color: black">{{ getChangeText(table.payload.freeTimeInvoice) }}</a>
          </span>
          <div v-show="isToolTipInvMsg && !$ekmtcCommon.isEmpty(table.payload.freeTimeInvoice.apvStsRmk)" class="tooltip_wrap short position_absolute" style="min-width: 350px; min-height: 150px;"><!-- tooltip_wrap   -->
            <div class="cont"><!-- cont -->
              <button class="close" @click="isToolTipInvMsg = false">close</button>
              <div v-html="table.payload.freeTimeInvoice.apvStsRmk"></div>
            </div><!-- cont -->
          </div><!-- tooltip_wrap // -->
          <!--          <span v-if="table.payload.freeTimeInvoice.apvStsCd === '04' || table.payload.freeTimeInvoice.apvStsCd === '07'" class="ml10">{{ table.payload.freeTimeInvoice.reqRsps ? " " + table.payload.freeTimeInvoice.reqRsps : "" }}</span>-->
        <!--span v-if="isReqRspsMsg" class="req-rsps-textbox">
          {{ reqRsps }}
        </span-->
        </span>
        <span class="cal_btn">
          <span>
            <input type="checkbox" :id="'isOnlyCost-' + table.id" v-model="isOnlyCost" />
            <label :for="'isOnlyCost-' + table.id">{{ $t('msg.SETT060G010.016') }}</label>
          </span>
        </span>
      </div>
      <div :style="headerFooterCss">
        <table class="tbl_col" style="border-bottom: none;">
          <colgroup>
            <col style="width:3%">
            <col style="width:12%">
            <col style="width:11%">
            <col style="width:8%">
            <col style="width:6%">
            <col style="width:8%">
            <col style="width:12%">
            <col style="width:8%">
            <col style="width:8%">
            <col style="width:12%">
            <col style="width:12%" v-if="ctrCd === 'KR' || ctrCd === 'PH' || vatChk">
          </colgroup>
          <thead>
            <tr>
              <th scope="col" class="pr0">
                <input type="checkbox" :id="'isCheckAll-' + table.id" @click="checkAll()" v-model="isCheckAll">
                <label :for="'isCheckAll-' + table.id"><span class="offscreen">선택</span></label>
              </th>
              <th scope="col">Port</th>
              <th scope="col">{{ $t('msg.SETT060G010.052') }}</th>
              <th scope="col">Start Date</th>
              <th scope="col">{{ $t('msg.SETT060G010.051') }}</th>
              <th scope="col">{{ table.typeText }} {{ $t('msg.SETT060G010.033') }}</th> <!--반출시한-->
              <th scope="col">{{ $t('msg.SETT060G010.034') }}</th> <!--반출(예정)일-->
              <th scope="col">{{ $t('msg.SETT060G010.050') }}</th>
              <th scope="col">{{ $t('msg.SETT060G010.011') }}</th> <!--상태-->
              <th scope="col">{{ $t('msg.SETT060G010.012') }}</th> <!--금액-->
              <th scope="col" v-if="ctrCd === 'KR' || ctrCd === 'PH' || vatChk">{{ $t('msg.SETT060G010.012') }}(VAT)</th> <!--금액(VAT)-->
            </tr>
          </thead>
        </table>
      </div>
      <div class="body-table-div">
        <table class="tbl_col" style="border-bottom: none;">
          <colgroup>
            <col style="width:3%">
            <col style="width:12%">
            <col style="width:11%">
            <col style="width:8%">
            <col style="width:6%">
            <col style="width:8%">
            <col style="width:12%">
            <col style="width:8%">
            <col style="width:8%">
            <col style="width:12%">
            <col style="width:12%" v-if="ctrCd === 'KR' || ctrCd === 'PH' || vatChk">
          </colgroup>
          <tbody>
            <tr v-if="table.payload.rows.length === 0">
              <td colspan="9">
                <!--// DEM/DET/COM 여부 -->
                <template v-if="table.id === 'DEM'">
                  {{ $t('msg.SETT060G010.035') }}<!-- No Demurrage Tariff -->
                </template>
                <template v-else-if="table.id === 'DET'">
                  {{ $t('msg.SETT060G010.036') }}<!-- No Detention Tariff -->
                </template>
                <template v-else-if="table.id === 'COM'">
                  {{ $t('msg.SETT060G010.037') }}<!-- No Combined Tariff -->
                </template>
                <template v-else-if="table.id === 'OFC'">
                  {{ $t('msg.SETT060G010.038') }}<!-- No Over Freetime Charge Tariff -->
                </template>
                <template v-else-if="table.id === 'MOR'">
                  {{ $t('msg.SETT060G010.039') }}<!-- No Monirotring Charge Tariff -->
                </template>
                <!--//~ DEM/DET/COM 여부 -->
                <!--// 수출/수입 여부 -->
                <template v-if="query.eiCatCd === 'O'">
                  {{ $t('msg.SETT060G010.040') }}<!-- (OUTBOUND) -->
                </template>
                <template v-if="query.eiCatCd === 'I'">
                  {{ $t('msg.SETT060G010.041') }}<!-- (INBOUND) -->
                </template>
              <!--//~ 수출/수입 여부 -->
              </td>
            </tr>
            <template v-for="(row, i) in table.payload.rows">
              <tr v-if="row.isShow" :key="'tr-' + table.id + '-' + i" @click="clickRow(i, $event)">
                <td class="pr0">
                  <template v-if="row.hasInvoice === 'N'">
                    <input type="checkbox" :id="'checkbox-' + table.id + '-' + i" v-model="row.isChecked" style="position: initial;">
                    <label :for="'checkbox-' + table.id + '-' + i" style="pointer-eventes: none"><span class="offscreen">선택</span></label>
                  </template>
                </td>
                <td>{{ row.parsePortNm }}</td>
                <td>{{ row.cntrNo }}</td>
                <td>{{ row.parseGateStrDt }}</td>
                <td>{{ row.freeDds }}</td>
                <td>{{ row.parseFreeEndDt }}</td>
                <td>
                  <!-- 여기가 달력 -->
                  <e-date-picker @input="changeExtsAlert(i, ...arguments)" v-model="row.extsDt" :stop-click-func="'Y'" />
                </td>
                <td>{{ row.ovrDds }}</td>
                <td>
                  <span v-if="row.cntrSts === 'Y'" class="label green md minwid50">{{ row.status }}</span>
                  <span v-else class="label red md minwid50">{{ row.status }}</span>
                </td>
                <td class="text_right">{{ numberFormat(row.totAmt) }}</td>
                <td class="text_right" v-if="ctrCd === 'KR' || ctrCd === 'PH' || vatChk">{{ numberFormat(row.vatAmt) }}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <div :style="headerFooterCss" style="padding-right:3px;">
        <table class="tbl_col">
          <colgroup>
            <col style="width:5%">
            <col style="width:12%">
            <col style="width:10%">
            <col style="width:12%">
            <col style="width:14%">
            <col style="width:11%">
            <col style="width:12%">
            <col style="width:12%">
            <col style="width:12%" v-if="ctrCd === 'KR' || ctrCd === 'PH' || vatChk">
          </colgroup>
          <tfoot>
            <tr>
              <th :colspan="ctrCd === 'KR' || ctrCd === 'PH' || vatChk ? 7 : 6" class="text_right title">SUB TOTAL</th>
              <th class="text_right" :colspan="ctrCd === 'KR' || ctrCd === 'PH' || vatChk ? 1 : 2">{{ numberFormat(subTotal[0]) }}</th>
              <th class="text_right" v-if="ctrCd === 'KR' || ctrCd === 'PH' || vatChk">{{ numberFormat(subTotal[1]) }}</th>
            </tr>
            <!-- D/C, GRAND TOTAL 구하기  -->
            <tr>
              <th :colspan="ctrCd === 'KR' || ctrCd === 'PH' || vatChk ? 7 : 6" class="text_right title">D/C AMOUNT</th>
              <th class="text_right">{{ dcRt }} %</th>
              <th class="text_right">{{ numberFormat(dcAmount) }}</th>
            </tr>
            <tr>
              <th :colspan="ctrCd === 'KR' || ctrCd === 'PH' || vatChk ? 7 : 6" class="text_right title">GRAND TOTAL</th>
              <th colspan="2" class="text_right">{{ numberFormat(grandTotal) }}</th>
            </tr>
          </tfoot>
        </table>
      </div>
      <div class="mt10 calc-table-footer">
        <div>
          <div v-show="$router.history.current.name !== 'FreeTimeRequest' && Number(tableInfo.idx) === Number(tableInfo.tmpIdx)">
            <!--            <a :class="auth.login ? 'button sm' : 'button sm disabled'" href="javascript:void(0)" @click="$emit('_openPopup')">DEM/DET Tariff</a>-->
            <a :class="auth.login ? 'button sm print ml5' : 'button sm print ml5 disabled'" @click="$emit('_screenToPdfDownload')" href="javascript:void(0)">
              <span class="btn_icon print"></span>{{ $t('msg.SETT060G010.002') }}
            </a>
          </div>
          <span v-if="query.eiCatCd == 'I' && ctrCd === 'KR' || ctrCd === 'PH' || vatChk">
            <a class="button sm ml5" href="javascript:void(0)" @click="fnDoKLNetSend()">{{ $t('msg.SETT060G010.022') }}</a>
            <span class="position_relative" style="margin-left: 5px;" @click="showTooltip(true)">
              <button class="tbl_icon help">도움말</button>
              <div v-show="isToolTip" class="tooltip_wrap short position_absolute" style="width:220px; display:none; left:30px; top:-111px;"><!-- tooltip_wrap   -->
                <div class="cont"><!-- cont -->
                  <button class="close" @click="showTooltip(false)">close</button>
                  <ul class="bul_list_sm t2">
                    <li>
                      {{ $t('msg.SETT060G010.023') }}
                    </li>
                  </ul>
                </div><!-- cont -->
              </div><!-- tooltip_wrap // -->
            </span>
          </span>
        </div>
        <!-- 일본, 인도네시아만 사용하는 버튼이 있음 // -->
        <!--        <div v-show="anCheck.bkgShprCstNo == this.auth.cstCd || table.eiCatCd === 'I'"> 2022.09.21 현업 요건 정리 후 재적용 예정 -->
        <div>
          <a href="javascript:void(0)" :class="btnCls.returnDateCancel" @click="returnDateCancelAlert()" v-if="['JP', 'ID'].indexOf(ctrCd) >= 0 && table.eiCatCd === 'I'">Return Date Cancel</a>
          <a href="javascript:void(0)" :class="btnCls.returnDateSubmit" @click="returnDateSubmit()" v-if="['JP', 'ID'].indexOf(ctrCd) >= 0 && table.eiCatCd === 'I'">Return Date Submit</a>
          <a v-if="ctrCd === 'KR'" :class="btnCls.previousIssuedInvoicePop" href="javascript:void(0)" @click="openPopup('PreviousIssuedInvoicePop')">Invoice 발행내역</a>
          <a v-if="ctrCd === 'KR'" :class="btnCls.inputInfoVaAcctPop" href="javascript:void(0)" @click="openVirtualAccountPopup('VirtualAccountInvoiceInfo')">{{ $t('msg.ONIM099P010.053') }}</a> <!-- 가상계좌 -->
          <a v-if="ctrCd === 'KR'" :class="btnCls.inputInfoPop" href="javascript:void(0)" @click="openPopup('InputTaxInvoiceInfo')">{{ $t('msg.SETT060G010.013') }}</a> <!-- 입금/계산서 정보입력 -->
          <a :class="btnCls.proformaInvoicePop" href="javascript:void(0)" @click="openPopup('ProformaInvoicePop')">Proforma Invoice</a>
          <a :class="btnCls.freeTimeExtensReqPop" href="javascript:void(0)" @click="openPopup('FreeTimeExtensReqPop')">Freetime {{ $t('msg.SETT060G010.014') }}</a> <!-- 연장요청 -->
          <a :class="btnCls.freeTimePrintPop" href="javascript:void(0)" @click="openPopup('FreeTimePrintPop')">Freetime {{ $t('msg.SETT060G010.015') }}</a> <!-- 요청내역 -->
        </div>
      </div>
    </div><!-- dem //  -->
  </div>
</template>
<script>
import EDatePicker from '@/components/common/EDatePicker'
import store from '@/store/index'

export default {
  name: 'Schedule',
  components: {
    EDatePicker
  },
  props: [
    'table',
    'auth',
    'query',
    'anCheck',
    'userType',
    'ctrCd',
    'vatChk',
    'tableInfo',
    'isFirstCalcTableIndex'
  ],
  data: function () {
  return {
      date: '',
      isOnlyCost: false,
      isCheckAll: false,
      checkCnt: 0,
      selectedRowIdx: null,
      updateDate: '',
      subTotal: [0, 0],
      headerFooterCss: {
        paddingRight: '0px'
      },
      isToolTip: false,
      isToolTipInvMsg: false,
      isToolTipWait: false,
      btnCls: {
        returnDateCancel: '',
        returnDateSubmit: '',
        previousIssuedInvoicePop: '',
        inputInfoPop: '',
        proformaInvoicePop: '',
        freeTimeExtensReqPop: '',
        freeTimePrintPop: '',
        inputInfoVaAcctPop: ''
      },
      isShowReturnBtn: false,
      dcAmount: 0,
      grandTotal: 0,
      dcRt: 0
    }
  },
  computed: {
      serviceLang () {
        return store.state.auth.serviceLang
      }
    },
  watch: {
    table: {
      deep: true,
      handler () {
        this.checkSingle()
        this.calcTotal()
        this.setBtnCls()

        //1384858 국내수출  선적지 MOR 미 발생 건일 경우 - FREETIME연장요청 버튼 비활성화
        if (this.ctrCd === 'KR' && this.table.eiCatCd === 'O' && this.table.id === 'MOR' && this.table.payload.rows.length === 0) {
          this.btnCls.freeTimeExtensReqPop = 'button blue sm mr3 disabled'
        }
        /*if (this.$ekmtcCommon.isNotEmpty(this.table.payload.freeTimeInvoice)) {
          this.isToolTipInvMsg = true
        }*/
      }
    },
    isOnlyCost: function () {
      this.$emit('child', { type: 'onlyCost', payload: { isOnlyCost: this.isOnlyCost, idx: this.table.idx } })
    },
    serviceLang () {
      this.setBtnCls()
    },
    ctrCd () {
      this.setBtnCls()
    }
  },
  created () {
    this.setBtnCls()
  },
  mounted () {

  },
  methods: {
    async setBtnCls () {
      if (this.ctrCd === 'KR') {
        this.btnCls.previousIssuedInvoicePop = this.auth.login ? 'button blue sm ml_auto mr3' : 'button blue sm ml_auto mr3 disabled'
        this.btnCls.inputInfoPop = this.auth.login ? 'button blue sm mr3' : 'button blue sm mr3 disabled'
        this.btnCls.proformaInvoicePop = this.auth.login ? 'button blue sm mr3' : 'button blue sm mr3 disabled'
        this.btnCls.freeTimeExtensReqPop = this.auth.login ? 'button blue sm mr3' : 'button blue sm mr3 disabled'
        this.btnCls.freeTimePrintPop = this.auth.login ? 'button blue sm' : 'button blue sm disabled'
        this.btnCls.inputInfoVaAcctPop = this.auth.login ? 'button blue sm' : 'button blue sm disabled'
      } else if (this.ctrCd !== 'KR' && this.table.eiCatCd === 'I') {
        this.btnCls.returnDateCancel = this.auth.login ? 'button blue sm ml_auto mr3' : 'button blue sm ml_auto mr3 disabled'
        this.btnCls.returnDateSubmit = this.auth.login ? 'button blue sm mr3' : 'button blue sm mr3 disabled'
        this.btnCls.proformaInvoicePop = this.auth.login ? 'button blue sm mr3' : 'button blue sm mr3 disabled'
        this.btnCls.freeTimeExtensReqPop = this.auth.login ? 'button blue sm mr3' : 'button blue sm mr3 disabled'
        this.btnCls.freeTimePrintPop = this.auth.login ? 'button blue sm' : 'button blue sm disabled'
     } else {
        this.btnCls.proformaInvoicePop = this.auth.login ? 'button blue sm ml_auto mr3' : 'button blue sm ml_auto mr3 disabled'
        this.btnCls.freeTimeExtensReqPop = this.auth.login ? 'button blue sm mr3' : 'button blue sm mr3 disabled'
        this.btnCls.freeTimePrintPop = this.auth.login ? 'button blue sm' : 'button blue sm disabled'
      }

      // 베트남만 적용. 로그인 화주와 검색 bl 화주가 같을 때만 프리타임 연장신청 가능
      if (this.ctrCd === 'VN' && this.$ekmtcCommon.isNotEmpty(this.anCheck)) {
        if (this.table.eiCatCd === 'O') {
          if (this.auth.login && this.anCheck.bkgShprCstNo === this.auth.cstCd) {
            this.btnCls.freeTimeExtensReqPop = 'button blue sm mr3'
          } else {
            this.btnCls.freeTimeExtensReqPop = 'button blue sm mr3 disabled'
          }
        }
      }
    },
    checkAll () {
      this.subTotal = [0, 0]
      this.dcAmount = 0
      this.dcRt = 0
      let cnt = 0
      this.isCheckAll = !this.isCheckAll
      for (let i = 0; i < this.table.payload.rows.length; i++) {
        if (this.table.payload.rows[i].isShow) {
          this.table.payload.rows[i].isChecked = this.isCheckAll
          cnt++
        } else {
          this.table.payload.rows[i].isChecked = false
        }
      }

      // 체크된 금액 계산
      for (let i = 0; i < this.table.payload.rows.length; i++) {
        if (this.table.payload.rows[i].isChecked) {
          this.subTotal[0] += this.table.payload.rows[i].totAmt * 1
          this.subTotal[1] += parseInt(this.table.payload.rows[i].vatAmt)
          this.dcAmount += parseInt(this.table.payload.rows[i].vatAmt) * parseInt(this.table.payload.rows[i].dcRt) / 100
          this.dcRt = parseInt(this.table.payload.rows[i].dcRt)
        } else {
          this.subTotal = [0, 0]
          this.dcAmount = 0
          this.dcRt = 0
        }
      }

      this.grandTotal = this.subTotal[1] - this.dcAmount

      if (this.isCheckAll) {
        this.checkCnt = cnt
      } else {
        this.checkCnt = 0
      }
    },
    checkSingle () {
      let checkCnt = 0
      let cnt = 0
      for (let row of this.table.payload.rows) {
        if (row.isShow) {
          cnt++
          if (row.isChecked) {
            checkCnt++
          }
        }
      }

      if (checkCnt === cnt && checkCnt > 0) {
        this.isCheckAll = true
      } else {
        this.isCheckAll = false
      }

      if (cnt > 10) {
        this.headerFooterCss = {
            paddingRight: '3px'
        }
      } else {
         this.headerFooterCss = {
            paddingRight: '0px'
        }
      }
      this.checkCnt = checkCnt
    },
    calcTotal () {
      this.subTotal = [0, 0]
      this.dcAmount = 0
      this.dcRt = 0
      let imsiDcAmt = 0
      for (let row of this.table.payload.rows) {
        if (row.isChecked) { // 체크 된게 있으면
          this.subTotal[0] += row.totAmt * 1
          this.subTotal[1] += parseInt(row.vatAmt)
          this.dcAmount += parseInt(row.vatAmt) * parseInt(row.dcRt) / 100
          this.dcRt = parseInt(row.dcRt)
          imsiDcAmt += parseInt((row.imsiDcAmt) ? row.imsiDcAmt : 0)
        }
      }
      //임시로직 추가
      this.dcAmount = (this.dcAmount + (imsiDcAmt + (imsiDcAmt * 0.1)))
      this.grandTotal = this.subTotal[1] - this.dcAmount

      if (this.grandTotal < 0) {
        this.dcAmount = 0
        this.grandTotal = 0
      }
    },
    changeExtsAlert (rowIndex, data) {
      console.log('@@@@ enter changeExtsAlert: ', data)
      console.log('@@@@ rowIndex:', rowIndex)
      console.log('@@@@ this.table.payload: ', this.table.payload)

      if (this.selectedRowIdx === null) {
        this.selectedRowIdx = rowIndex
      }

      this.updateDate = data
      if (this.table.payload.rows.length > 1) {
          let obj = {
          onConfirm: this.AllChangeExts,
          onClose: this.changeExts,
          customCloseBtnClass: 'button blue lg',
          customCloseBtnText: this.$i18n.t('msg.SETT060G010.021'),
          customConfirmBtnText: this.$i18n.t('msg.SETT060G010.020'),
          useConfirmBtn: true,
          customConfirmBtnClass: 'button blue lg',
          message: this.$i18n.t('msg.SETT060G010.019'),
          type: 'info'
        }
        this.$ekmtcCommon.alert(obj)
      } else {
        this.changeExts()
      }
    },
    AllChangeExts () {
      const arr = []
      for (let i = 0; i < this.table.payload.rows.length; i++) {
        if (this.table.payload.rows[i].isShow) {
          const hasInvoice = this.table.payload.rows[i].hasInvoice || ''
          const cntrSts = this.table.payload.rows[i].cntrSts || ''
          const cntrNo = this.table.payload.rows[i].cntrNo || ''
          // console.log(i + ')' + cntrNo + ', ' + hasInvoice + ', ' + cntrSts)
          if (hasInvoice === 'Y' || cntrSts === 'Y') {
            //continue
          }
          arr.push({
            idx: this.table.idx,
            rowIdx: i,
            cntrNo: this.table.payload.rows[i].cntrNo,
            invRno: this.table.payload.rows[i].invRno || '',
            extsDt: this.updateDate
          })
        }
      }

      this.$emit('child', {
        type: 'AllChangeExts',
        payload: arr
      })
    },
    changeExts () {
      console.log('@@@@ enter changeExts')
      console.log('@@@@ this.table: ', this.table)
      console.log('@@@@ this.selectedRowIdx: ', this.selectedRowIdx)
      this.$emit('child', {
        type: 'changeExts',
        payload: {
          idx: this.table.idx,
          rowIdx: this.selectedRowIdx,
          cntrNo: this.table.payload.rows[this.selectedRowIdx].cntrNo,
          invRno: this.table.payload.rows[this.selectedRowIdx].invRno || '',
          extsDt: this.updateDate
        }
      })
    },
    returnDateCancelAlert () {
        let obj = {
        onConfirm: this.returnDateCancel,
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg',
        customCloseBtnText: this.$i18n.t('msg.SETT060G010.021'),
        customConfirmBtnText: this.$i18n.t('msg.SETT060G010.020'),
        useConfirmBtn: true,
        customConfirmBtnClass: 'button blue lg',
        message: this.$t('msg.CSBK100.502'),
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    returnDateCancel () {
      this.$emit('child', {
        type: 'returnDateCancel',
        payload: {
          idx: this.table.idx
        }
      })
    },
    returnDateSubmitAlert () {
        let obj = {
        onConfirm: this.returnDateSubmit,
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg',
        customCloseBtnText: this.$i18n.t('msg.SETT060G010.021'),
        customConfirmBtnText: this.$i18n.t('msg.SETT060G010.020'),
        useConfirmBtn: true,
        customConfirmBtnClass: 'button blue lg',
        message: 'After submit the return date, please upload payment evidence in D/O request. <br/> Would you like to register?',
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    returnDateSubmit () {
      this.$emit('child', {
        type: 'returnDateSubmit',
        payload: {
          idx: this.table.idx
        }
      })
    },
    closeFun () {
      this.$Simplert.close()
    },
    clickRow (idx, e) {
      console.log('@@@@ clickRow')
      this.selectedRowIdx = idx

      this.clickCalcTotal(idx, e.target.checked)
    },
    // 체크박스 체크값에 따라 total값 더하고 빼기
    clickCalcTotal (idx, checked) {
      if (checked === undefined) return
      console.log('this.table.payload.rows[idx].totAmt', this.table.payload.rows[idx])

      if (checked) {
        this.subTotal[0] += this.table.payload.rows[idx].totAmt * 1
        this.subTotal[1] += parseInt(this.table.payload.rows[idx].vatAmt)
        this.dcAmount += parseInt(this.table.payload.rows[idx].vatAmt) * parseInt(this.table.payload.rows[idx].dcRt) / 100
        this.dcRt = parseInt(this.table.payload.rows[idx].dcRt)
      } else {
        // this.subTotal[0] -= this.table.payload.rows[idx].totAmt * 1
        // this.subTotal[1] -= parseInt(this.table.payload.rows[idx].vatAmt)a
        if (this.subTotal[0] > 0) {
          this.subTotal[0] -= this.table.payload.rows[idx].totAmt * 1
        }

        if (this.subTotal[1] > 0) {
          this.subTotal[1] -= parseInt(this.table.payload.rows[idx].vatAmt)
        }

        if (this.dcAmount > 0) {
          this.dcAmount -= parseInt(this.table.payload.rows[idx].vatAmt) * parseInt(this.table.payload.rows[idx].dcRt) / 100
        }

        if (this.dcAmount <= 0) {
          this.dcRt = 0
        }
      }

      this.grandTotal = this.subTotal[1] - this.dcAmount
      // this.subTotal = [0, 0]
      // for (let row of this.table.payload.rows) {
      //   this.subTotal[0] += row.totAmt * 1
      //   this.subTotal[1] += parseInt(row.vatAmt)
      // }
    },
    numberFormat (x) {
      // 소숫점인 경우 첫번째 자리 유지하도록 정책 반영 (icc와 동일)
      if (x % 1 !== 0) {
        x = x.toFixed(1)
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    showTooltip (flag) {
      if (!this.isToolTipWait) {
        this.isToolTip = flag
        this.isToolTipWait = true
      }
      setTimeout(() => {
       this.isToolTipWait = false
      }, 100)
    },
    openPopup (compNm) {
      // 프리타임 연장요청시 수입인 경우 인보이스 신청(request, pending, accept) 상태는 연장요청 못하도록 조건 추가함
      if (this.query.eiCatCd === 'I' && compNm === 'FreeTimeExtensReqPop' && this.table.payload.freeTimeInvoice !== null) {
        if (this.table.payload.freeTimeInvoice.apvStsCd === '02' || this.table.payload.freeTimeInvoice.apvStsCd === '07' || this.table.payload.freeTimeInvoice.apvStsCd === '03') {
          this.$ekmtcCommon.alertDefault('Invoice 신청시 Freetime 연장이 불가합니다.')
          return
        }
      }

       this.$emit('child', {
        type: 'openPopup',
        payload: {
          compNm: compNm,
          idx: this.table.idx,
          freeTimeInvoice: this.table.payload.freeTimeInvoice
        }
      })
    },
    async openVirtualAccountPopup (compNm) {
      this.$emit('child', {
        type: 'openPopup',
        payload: {
          compNm: compNm,
          idx: this.table.idx,
          freeTimeInvoice: this.table.payload.freeTimeInvoice
        }
      })
    },
    fnDoKLNetSend () {
      this.$emit('child', {
        type: 'fnDoKLNetSend',
        payload: {
          idx: this.table.idx
        }
      })
    },
    getChangeText (fi) {
      let res = ''
      if (fi !== null && this.$ekmtcCommon.isNotEmpty(fi.apvStsNm)) {
        res = '[ Invoice No : ' + fi.invIssNo + ' ] - [ 처리상태 : ' + fi.apvStsNm + ' (클릭) ]'
      }
      return res
    },
    getChangeTextVa (fi) {
      let res = ''
      if (fi !== null) {
        res = '[ Invoice No : ' + fi.invIssNo + ' ] - [ 처리상태 : ' + this.getApvStsCdTextVa(fi.apvStsCd) + (fi.demDetCatCd === '14' ? ' (클릭) ]' : ' ]')
      }
      return res
    },
    getApvStsCdText (cd) {
      let res = ''
      switch (cd) {
        case '01':
          res = 'Not Yet Requested'
          break
        case '02':
          res = 'Requested'
          break
        case '03':
          res = 'Accepted'
          break
        case '04':
          res = 'Rejected'
          break
        case '05':
          res = 'Chk by Port'
          break
        case '06':
          res = 'Pick-up Accepted'
          break
        case '07':
          res = 'Pending'
          break
        case '08':
          res = 'Chk by Shpr'
          break
        case '09':
          res = 'Chk by Liner'
          break
        case '10':
          res = 'Cancelled'
          break
      }
      return res
    },
    getApvStsCdTextVa (cd) {
      let res = ''
      switch (cd) {
        case '01':
          res = 'Not Yet Requested'
          break
        case '02':
          res = 'Requested'
          break
        case '03':
          res = '승인되었습니다.'
          break
        case '04':
          res = 'Rejected'
          break
        case '05':
          res = 'Chk by Port'
          break
        case '06':
          res = 'Pick-up Accepted'
          break
        case '07':
          res = '입금완료'
          break
        case '08':
          res = 'Chk by Shpr'
          break
        case '09':
          res = 'Chk by Liner'
          break
        case '10':
          res = 'Cancelled'
          break
      }
      return res
    },
    isEmpty (value) {
      if (value === '' || value === null || value === undefined || (value != null && typeof value === 'object' && !Object.keys(value).length)) {
          return true
      } else {
          return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .body-table-div {
    overflow: auto;
    max-height: 340px;
  }

  .body-table-div::-webkit-scrollbar {
    width: 3px;
    background: #fff;
  }
  .body-table-div::-webkit-scrollbar-thumb {
    background: #999;
  }

  .req-rsps-textbox {
    position: absolute;
    display: block;
    background: #fff;
    max-width: 300px;
    padding: 5px;
    outline: 1px solid #c0c0c0;
    border-radius: 5px;
    top: 25px;
    font-size: 13px;
  }
  .calc-table-footer {
    display: flex;
    justify-content: space-between;
    width: 100%;

    & > div {
      &:nth-of-type(1) {
        width: auto;
        display: flex;
      }
      &:nth-of-type(2) {
        width: auto;
      }

    }
  }
</style>
